<template>
  <v-main class="main">
    <v-container fluid>
      <div class="main-content">
        <transition name="slide" mode="out-in">
          <router-view></router-view>
        </transition>
      </div>
    </v-container>
  </v-main>
</template>

<script>
export default {};
</script>

<style scoped>
.main {
  min-height: 100vh;
  height: 100%;
  background: #eeeeee;
}

.container {
  height: 100%;
  padding: 40px 20px !important;
}

.container > .main-content {
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  border: 8px;
}

.slide-enter,
.slide-leave-to {
  transform: translate(-50px);
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s;
}
</style>
