<template>
  <div>
    <Sidebar :resource="drawer" />

    <v-app-bar color="#FFFFFF" app class="navbar" elevation="0">
      <div class="app-itens">
        <div class="sidebar-action">
          <v-btn @click="clicando" color="#127EDE" icon elevation="0" small>
            <v-icon color="#0782EE">{{ menuIcon }}</v-icon>
          </v-btn>

          <span class="pageIndicator">{{
            this.$router.history.current.name
          }}</span>
        </div>
        <div style="display:flex; justify-content:start; align-items:center;">
          <h4 style="margin-right: 10px;">{{$store.state.authUser.fullName}}</h4>
          <Menu :resource="$store.state.authUser" />
        </div>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import Sidebar from "../sidebar/Sidebar-comp.vue";
import Menu from "./MenuNavbar-comp.vue";
import axios from "@/axios";

export default {
  data() {
    return {
      drawer: null,
      menuIcon: "mdi-menu",
      authUser: {
        initials: "",
        fullName: "",
        email: "",
        id_profile_user: "",
      },
    };
  },

  components: {
    Sidebar,
    Menu,
  },

  created() {
    axios.get(`/auth/me`).then((response) => {
      //this.authUser.initials = response.data.name_user.substr(0, 1);
      //this.authUser.fullName = response.data.name_user.split(' ')[0];
      this.$store.state.authUser.email = response.data.email;
      this.$store.state.authUser.fullName = response.data.name_user;
      this.$store.state.authUser.id_profile_user = response.data.id_profile_user;
      this.$store.state.authUser.id_unity_user = response.data.id_unity_user;      
    });
  },

  methods: {
    clicando() {
      this.drawer = !this.drawer;
    },
  },
};
</script>

<style>
.navbar {
  position: absolute !important;
  margin-top: 20px !important;
  margin: 20px;
  border-radius: 10px !important;
}

.app-itens {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.sidebar-action {
  display: flex;
  align-items: center;
}

.sidebar-action > .pageIndicator {
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
  margin-left: 10px;
}
</style>
