<template>
  <div>
    <v-btn depressed rounded text @click="dialogResetPass">
      {{ resource }}
    </v-btn>
    <v-dialog v-model="dialogPass" width="500px" persistent>
      <v-card class="">
        <div class="box-header-modal">
          <div class="box-indicator">
            <div class="indicator"></div>
            <h5>{{ resource }}</h5>
          </div>
          <v-btn
            class="btn-close"
            fab
            x-small
            color="primary"
            @click="dialogPass = false"
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </div>

        <v-stepper elevation="0" v-model="e1">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1"> </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 2" step="2"> </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 3" step="3"> </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="4"> </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-container>
              <AlertError
                class="mb-5"
                v-model="displayError"
                :resource="displayError"
              />
            </v-container>

            <v-stepper-content step="1">
              <div class="card-step text-center">
                <div class="box-step-indicator">
                  <h4>Infome o seu e-mail cadastrado</h4>
                  <div class="indicator"></div>
                </div>

                <v-form
                  @submit.prevent="changePasswordEmail"
                  v-model="valid"
                  lazy-validation
                  ref="form1"
                >
                  <v-text-field
                    label="E-MAIL"
                    outlined
                    dense
                    v-model="email"
                    :rules="emailRules"
                  ></v-text-field>
                  <v-btn
                    block
                    depressed
                    color="primary"
                    type="submit"
                    :loading="carregamento"
                    :disabled="desativado"
                    >CONTINUAR</v-btn
                  >
                </v-form>
              </div>
            </v-stepper-content>

            <v-stepper-content step="2">
              <div class="card-step text-center">
                <div class="box-step-indicator">
                  <h4>Infome o código</h4>
                  <div class="indicator"></div>
                </div>
                <v-form
                  @submit.prevent="changePasswordCode"
                  v-model="valid"
                  lazy-validation
                  ref="form2"
                >
                  <v-otp-input
                    length="5"
                    dense
                    v-model="code"
                    :rules="codeRules"
                    :error="codeError"
                  ></v-otp-input>
                  <v-btn
                    class="mt-4"
                    block
                    depressed
                    color="primary"
                    type="submit"
                    :loading="carregamento"
                    :disabled="desativado"
                    >CONTINUAR</v-btn
                  >
                </v-form>
              </div>
            </v-stepper-content>

            <v-stepper-content step="3">
              <div class="card-step text-center">
                <div class="box-step-indicator">
                  <h4>Escolha uma nova senha</h4>
                  <div class="indicator"></div>
                </div>
                <v-form
                  @submit.prevent="changePassword"
                  v-model="valid"
                  lazy-validation
                  ref="form"
                >
                  <v-text-field
                    label="SENHA"
                    outlined
                    dense
                    v-model="newpass"
                    :error="passError"
                    :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPass ? 'text' : 'password'"
                    @click:append="showPass = !showPass"
                    :rules="passRules"
                  ></v-text-field>
                  <div class="mr-1 ml-1"></div>
                  <v-text-field
                    label="CONFIRME A SENHA"
                    outlined
                    dense
                    v-model="confirmNewpass"
                    :error="passError"
                    :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPass2 ? 'text' : 'password'"
                    @click:append="showPass2 = !showPass"
                    :rules="passRules"
                  ></v-text-field>
                  <v-btn
                    class="mt-1"
                    block
                    depressed
                    color="primary"
                    type="submit"
                    :loading="carregamento"
                    :disabled="desativado"
                    >FINALIZAR</v-btn
                  >
                </v-form>
              </div>
            </v-stepper-content>

            <v-stepper-content step="4">
              <div class="card-success text-center">
                <v-icon color="#2ecc71" size="44">mdi-check-all</v-icon>
                <h4 class="mt-2 mb-2">Senha alterada!</h4>
                <span class="mb-2">Sua senha foi alterada com sucesso.</span>

                <div class="space"></div>

                <v-progress-linear
                  :value="teste"
                  :size="100"
                  :width="15"
                  color="green"
                ></v-progress-linear>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "@/axios";
import AlertError from "../alerts/Error-component.vue";

export default {
  data() {
    return {
      e1: 1,
      dialogPass: false,
      valid: true,
      email: "",
      emailRules: [
        (v) => !!v || "O campo é obrigatório",
        (v) => /.+@.+\..+/.test(v) || "O e-mail não é válido.",
      ],
      code: "",
      codeError: false,
      codeRules: [(v) => !!v || "O campo é obrigatório"],

      showPass: false,
      showPass2: false,
      newpass: "",
      passError: false,
      confirmNewpass: "",
      passRules: [
        (v) => !!v || "O campo é obrigatório",
        (v) => (v && v.length > 5) || "Mínimo de 6 caracteres",
      ],

      displayError: {
        display: false,
        message: [],
      },

      messageEmail: "",
      messageCode: "",
      messagePass: "",

      snackbar: false,
      snackbarMessage: "",
      carregamento: false,
      desativado: false,

      interval: {},
      teste: 0,
      value2: 5,
    };
  },

  props: {
    resource: {
      type: String,
    },
  },

  components: {
    AlertError,
  },

  mounted() {
    this.email = this.$store.state.authUser.email;
    this.interval = setInterval(() => {
      this.teste += 20;
      if (this.value2 != 0) {
        this.value2 -= 1;
      }
    }, 1000);
  },

  methods: {
    dialogResetPass() {
      setTimeout(() => {
        this.dialogPass = true;
      }, 1);
    },

    changePasswordEmail() {
      if (this.$refs.form1.validate() == true) {
        const dados = new Object();
        dados.email = this.email;

        this.carregamento = true;
        this.desativado = true;

        axios
          .post(`/forget-password`, dados)
          .then((response) => {
            this.e1 = 2;
            this.messageEmail = response.data.message;
            this.carregamento = false;
            this.desativado = false;
            this.displayError.display = false;
          })
          .catch((err) => {
            err;
            var error = this.$store.state.errorMessage;
            this.displayError.message = error.toString();
            this.displayError.display = true;

            this.carregamento = false;
            this.desativado = false;
          });
      }
    },

    changePasswordCode() {
      if (this.$refs.form2.validate() == true) {
        const dados = new Object();
        dados.email = this.email;
        dados.token = this.code;

        this.carregamento = true;
        this.desativado = true;

        if (dados.token.length == 5) {
          axios
            .post(`auth/valid-token`, dados)
            .then((response) => {
              if (response == undefined) {
                var error = this.$store.state.errorMessage;
                this.displayError.message = error.toString();
                this.displayError.display = true;

                this.carregamento = false;
                this.desativado = false;
              } else {
                this.e1 = 3;
                this.messageCode = response.data.message;
                this.carregamento = false;
                this.desativado = false;
                this.displayError.display = false;
              }
            })
            .catch((err) => {
              err;
              var error = this.$store.state.errorMessage;
              this.displayError.message = error.toString();
              this.displayError.display = true;
              this.carregamento = false;
              this.desativado = false;
            });
        } else {
          this.codeError = true;
          setTimeout(() => {
            this.codeError = false;
          }, 1000);
        }
      }
    },

    changePassword() {
      if (this.$refs.form1.validate() == true) {
        this.carregamento = true;
        this.desativado = true;

        if (this.newpass == this.confirmNewpass) {
          const dados = new Object();
          dados.email = this.email;
          dados.token = this.code;
          dados.password = this.newpass;
          dados.password_confirmation = this.confirmNewpass;

          axios
            .post(`/reset-password`, dados)
            .then((response) => {
              this.e1 = 4;
              this.messagePass = response.data.message;
              this.carregamento = false;
              this.desativado = false;
              this.displayError.display = false;
            })
            .catch((err) => {
              err;
              var error = this.$store.state.errorMessage;
              this.displayError.message = error.toString();
              this.displayError.display = true;

              this.carregamento = false;
              this.desativado = false;
            });
        } else {
          this.carregamento = false;
          this.desativado = false;
          this.displayError.message = "Preencha os campos corretamente.";
          this.displayError.display = true;
          this.passError = true;
          setTimeout(() => {
            this.passError = false;
          }, 3000);
        }
      }
    },
  },
};
</script>

<style scoped>
.box-header-modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

.box-header-modal > .box-indicator {
  display: flex;
  align-items: center;
}

.box-header-modal > .box-indicator > .indicator {
  height: 18px;
  width: 5px;
  background: linear-gradient(
    174deg,
    rgb(12, 82, 231) 0%,
    rgba(20, 163, 232, 1) 100%
  );
  margin-right: 5px;
  border-radius: 10px;
}

.box-header-modal > .box-indicator > h5 {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}

.v-stepper__header {
  border-radius: 10px;
  box-shadow: none !important;
}

.displayError {
  margin: 0 15px;
}

.v-stepper__content {
  padding: 0 16px 16px 16px !important;
}

.card-step {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: 1px dashed rgb(39, 138, 230);
  padding: 25px;
  border-radius: 10px;
}

.card-step > .box-step-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.card-step > .box-step-indicator > h4 {
  font-family: "Poppins";
  font-weight: 400;
  color: #000;
}

.card-step > .box-step-indicator > .indicator {
  background: linear-gradient(
    174deg,
    rgb(12, 82, 231) 0%,
    rgba(20, 163, 232, 1) 100%
  );
  height: 5px;
  width: 85px;
  border-radius: 8px;
}

.card-success {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: 1px dashed rgb(5, 151, 37);
  padding: 25px;
  border-radius: 10px;
}

.space {
  height: 25px;
}
</style>
