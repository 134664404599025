<template>
  <v-navigation-drawer app class="sidebar" v-model="drawer">
    <div class="box-sidebar-logo mb-5">
      <img class="logo-img" src="../../assets/Logosisvocbranca.svg" alt="" />
      <h3 class="text-logo"></h3>
      <h6 class="text-system-name"></h6>
      <div id="example"></div>
    </div>

    <div class="card-menu" width="600">
      <v-list>
        <h6 class="text-section-menu">Menu</h6>
        <v-list-item-group
          v-if="$store.state.authUser.id_profile_user == 1"
          class="list-group"
        >
          <router-link to="/dashboard" class="link" active-class="link-ativo">
            <v-icon size="22" class="icon">mdi-view-dashboard</v-icon>
            <span>Dashboard</span>
          </router-link>
        </v-list-item-group>
        <v-list-item-group
          v-if="$store.state.authUser.id_profile_user == 1"
          class="list-group"
        >
          <router-link to="/users" class="link" active-class="link-ativo">
            <v-icon size="22" class="icon">mdi-account-supervisor</v-icon>
            <span>Usuários</span>
          </router-link>
        </v-list-item-group>

        <v-list-group class="list-group v-list-item-sidebar">
          <template v-slot:activator class="link v-list-item-sidebar">
            <v-list-item-title class="submenu-header">
              <v-icon size="22" class="icon">mdi-calendar-month</v-icon>
              <span>Movimentações </span>
            </v-list-item-title>
          </template>

          <router-link
            to="/person"
            class="link ml-2 mr-2"
            active-class="link-ativo"
          >
            <span>Registrar Entrada</span>
          </router-link>
          <router-link
            to="/PesquisarMovi"
            class="link ml-2 mr-2"
            active-class="link-ativo"
          >
            <span>Movimentação</span>
          </router-link>
        </v-list-group>
        <!-- <v-list-item-group class="list-group">
          <router-link to="" class="declaration">
            <a @click="downloadDeclaration()">
              <v-icon size="22" class="icon">mdi-file-document-multiple</v-icon>
              <span
                style="
                  color: black;
                  font-family: 'Poppins', sans-serif;
                  font-size: 13px;
                  font-weight: 500;
                "
              >
                Declaração de Comparecimento para Visitante</span
              >
            </a>
          </router-link>
        </v-list-item-group> -->
      </v-list>
    </div>
  </v-navigation-drawer>
</template>
<script>
import axios from "@/axios";
export default {
  data() {
    return {
      drawer: null,
    };
  },

  props: {
    resource: {
      type: Boolean,
    },
  },
  created() {
    if (this.$route.path !== "/person") {
      this.$store.state.stepperPatient = true;
      this.$store.state.stepperVisitors = false;
    }
  },
  methods: {
    downloadDeclaration() {
      var obj = { download: "DECLARACAO_DE_COMPARECIMENTO_RATIFICADO.pdf" };
      axios
        .get(
          "/downloads",
          {
            params: {
              ...obj,
            },
          },
          { responseType: "blob" }
        )
        .then((res) => {
          var docUrl = document.createElement("a");
          docUrl.href = res.data;
          docUrl.setAttribute("download", "declaracao_comparecimento.pdf");
          document.body.appendChild(docUrl);
          docUrl.click();
        });
    },
  },
  watch: {
    resource: function () {
      this.drawer = !this.drawer;
    },
  },
};
</script>
<style>
.v-navigation-drawer__border {
  width: 0px !important;
}

.sidebar {
  background: transparent;
  padding: 0, 01px;
  border-radius: 0px;
}

.box-sidebar-logo {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  background: linear-gradient(
    to bottom right,
    rgb(14, 0, 141),
    rgb(0, 136, 84)
  ) !important;
  border-radius: 0rem;
  min-height: 20px;
}

.logo-img {
  height: 95x;
  width: 260px;
}

.text-logo {
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  font-weight: 700;
  color: #ffffff;
}

.text-system-name {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
}

.card-menu {
  padding: 10px;
  background: #ffffff;
  border-radius: 10px;
}

.text-section-menu {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 20px;
}

.list-group {
  display: flex;
  flex-direction: column;
}

.link {
  padding: 10px;
  margin: 5px 0;
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: 0.8s;
}

.link:hover {
  background: #1194ff;
  border-radius: 10px;
  transition: 0.8s;
}
.declaration {
  padding: 10px;
  margin: 5px 0;
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: 0.8s;
}
.declaration:hover {
  background: #8dc7f6;
  border-radius: 10px;
  transition: 0.8s;
}

.link > .icon {
  margin-right: 4px;
  color: #000000 !important;
}

.link > span {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: #000000;
}

.link-ativo {
  background: #cde6ff;
  border: 1px solid #fafafa;
  border-radius: 10px;
  color: rgb(0, 18, 177);
}

.submenu-header {
  font-family: "Poppins", sans-serif;
  font-size: 14px !important;
  font-weight: 500;
  color: #000000;
}

/* Mobile */
.v-navigation-drawer--is-mobile:not(.v-navigation-drawer--close) {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%) !important;
  background: rgb(248, 248, 248) !important;
}
</style>
