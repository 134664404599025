<template>
  <div class="text-center">
    <v-menu min-width="250px">
      <template v-slot:activator="{ on, attrs }">
        <v-avatar color="blue" size="36" v-bind="attrs" v-on="on">
          <span class="white--text text-h6">{{ resource.initials }}</span>
        </v-avatar>
      </template>

      <v-card>
        <v-list-item-content class="justify-center">
          <div class="pa-5 text-left">
            <div class="box">
              <v-avatar color="blue" class="mr-2" size="26">
                <span class="white--text">{{ resource.initials }}</span>
              </v-avatar>
              <div class="box-name">
                <small class="saudacao">Olá,</small>
                <h5>{{ resource.fullName }}</h5>
              </div>
            </div>

            <div class="box-infos">
              <span>Email</span>
              <span class="text-caption mt-1">
                {{ resource.email }}
              </span>
            </div>

            <div class="box-infos">
              <span>Localização</span>
              <span class="text-caption mt-1"> -- </span>
            </div>
            <div class="box-btn-actions text-center">
              <v-divider class="my-3"></v-divider>
              <NewPass :resource="btnName" />
              <v-divider class="my-3"></v-divider>
              <v-btn depressed rounded text @click="logout">
                Desconectar
              </v-btn>
            </div>
          </div>
        </v-list-item-content>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import NewPass from "../login/NewPassword-comp.vue";
export default {
  data() {
    return {
      btnName: "ALTERAR SENHA",
    };
  },

  props: {
    resource: {
      type: Object,
    },
  },

  components: {
    NewPass,
  },

  mounted() {
  },

  methods: {
    logout() {
      localStorage.removeItem("token");
      this.$router.push("/");
    },
  },
};
</script>

<style>
.v-menu__content {
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px !important;
}
.box {
  display: flex;
  align-items: center;
}

.box-infos {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.box-infos > span {
  font-size: 12px;
  margin-bottom: -3px;
  font-weight: 600;
}

.box-name {
  display: flex;
  flex-direction: column;
}

.box-name > small {
  font-size: 11px;
}

.box-name > h5 {
  font-size: 14px;
}
</style>
